<template>
  <div class="card-body mt-2 white">
    <div class="title">
      <i class="bi bi-journal-richtext"
        ><span style="font-size: 14px; margin-left: 5px; color: black"
          >ใบงานที่ : {{ appeals.id }}
        </span></i
      ><img :src="appeals.icon" class="history-image-logo" alt="" />
    </div>

    <div
      v-if="appeals.appeal_images.length !== 0"
      class="wrapper"
      style="margin-bottom: 10px"
    >
      <img
        v-for="image in appeals.appeal_images"
        :key="image"
        :src="image.src"
        class="mt-2"
        style="object-fit: cover; width: 100%"
        alt="..."
      />
    </div>

    <div v-else>
      <div class="noimg">
        <i class="bi bi-card-image icnoimg"></i>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "Appeal-Detail-Images",
  props: ["appeals"],
});
</script>

<style scoped>
.wrapper {
  max-height: 255px;
  height: 255px;
  display: flex;
  overflow-x: auto;
  padding-top: 0;
  width: 100%;
}
.wrapper::-webkit-scrollbar {
  width: 0;
}
.wrapper .item {
  max-width: 200px;
  height: 200px;
  max-height: 200px;
  margin-right: 10px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgb(0 0 0 / 19%), 0 6px 6px rgb(0 0 0 / 23%);
  position: relative;
}
.history-image-logo {
  vertical-align: bottom;
  display: inline-block;
  width: 40px;
  height: 40px;
  border: 1.3px solid #dee2e6;
  border-radius: 15px;
  float: right;
}
.noimg {
  margin-top: 50px;
  width: 100%;
  height: 250px;
  background-color: #e9ecef;
  position: relative;
}
.icnoimg {
  position: absolute;
  top: 100px;
  width: 100%;
  text-align: center;
  font-size: 50px;
  color: #f9f7f7;
}
</style>
