export const SET_APPEAL = "setAppeal";
export const SET_APPEAL_TO = "setAppealTo";

export default {
  state: {
    appeal: {
      status_undefined:false
    },
    appeal_to: [],
  },
  getters: {
    getAppeal(state) {
      return state.appeal;
    },
    getAppealTo(state) {
      return state.appeal_to;
    },
  },
  actions: {
    [SET_APPEAL](state, payload) {
      state.commit(SET_APPEAL, payload);
    },
    [SET_APPEAL_TO](state, payload) {
      state.commit(SET_APPEAL_TO, payload);
    },
  },
  mutations: {
    [SET_APPEAL](state, payload) {
      state.appeal = {
        ...state.appeal,
        ...payload,
      };
    },
    [SET_APPEAL_TO](state, payload) {
      state.appeal_to = {
        ...payload,
      };
    },
  },
};
