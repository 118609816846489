<template>
  <div class="card-body mt-2 white">
    <div class="title">
      <i class="bi bi-calendar2-week"
        ><span style="font-size: 14px; margin-left: 5px; color: black"
          >การดำเนินการ</span
        ></i
      >
    </div>

    <div class="timeline p-4 block">
      <div
        v-for="(item, index) in appeals.timeline"
        :key="index"
        class="tl-item w-100"
      >
        <div class="tl-dot" :class="getClass(item.status)"></div>
        <div class="tl-content">
          <div class="">{{ item.status }}</div>
          <div class="tl-detail text-muted mt-1">
            {{ item.status_detail }}
          </div>
          <div class="tl-detail text-muted mt-1">
            {{ dateThai(item.created_at) }}
          </div>
          <div v-if="item.admin" class="tl-detail text-muted mt-1">
            โดย {{ item.admin }}
          </div>
          <div v-else class="tl-detail text-muted mt-1">
            โดย {{ Profire.firstname }} {{ Profire.lastname }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, inject } from "vue";
import moment from "moment";
export default defineComponent({
  name: "Appeal-Detail-Timeline",
  props: ["appeals"],
  setup() {
    const store = inject("store");
    const Profire = store.getters.getProfire;

    function getClass(property) {
      if (property === "รอตรวจสอบ") {
        return "b-danger";
      } else if (property === "รับคำร้อง") {
        return "b-warning";
      } else if (property === "ดำเนินการ") {
        return "b-primary";
      } else {
        return "b-success";
      }
    }

    function dateThai(date) {
      let y = moment(date).format("yyy");
      let year = parseInt(y) + 543;
      let march = moment(date).locale("th");

      return march.format("HH : mm : ss  วันที่ D MMMM " + year);
    }

    return {
      Profire,
      getClass,
      dateThai,
    };
  },
});
</script>

<style scoped>
.tl-item {
  border-radius: 3px;
  position: relative;
  display: -ms-flexbox;
  display: flex;
}

.tl-item > * {
  padding: 10px;
}

.tl-item .avatar {
  z-index: 2;
}

.tl-item:last-child .tl-dot:after {
  display: none;
}

.tl-item.active .tl-dot:before {
  border-color: #448bff;
  box-shadow: 0 0 0 4px rgba(68, 139, 255, 0.2);
}

.tl-item:last-child .tl-dot:after {
  display: none;
}

.tl-item.active .tl-dot:before {
  border-color: #448bff;
  box-shadow: 0 0 0 4px rgba(68, 139, 255, 0.2);
}

.tl-dot {
  position: relative;
  border-color: rgba(160, 175, 185, 0.15);
}

.tl-dot:after,
.tl-dot:before {
  content: "";
  position: absolute;
  border-color: inherit;
  border-width: 2px;
  border-style: solid;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  top: 15px;
  left: 50%;
  transform: translateX(-50%);
}

.tl-dot:after {
  width: 0;
  height: auto;
  top: 25px;
  bottom: -15px;
  border-right-width: 0;
  border-top-width: 0;
  border-bottom-width: 0;
  border-radius: 0;
}

tl-item.active .tl-dot:before {
  border-color: #448bff;
  box-shadow: 0 0 0 4px rgba(68, 139, 255, 0.2);
}

.tl-dot {
  position: relative;
  border-color: rgba(160, 175, 185, 0.15);
}

.tl-dot:after,
.tl-dot:before {
  content: "";
  position: absolute;
  border-color: inherit;
  border-width: 2px;
  border-style: solid;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  top: 15px;
  left: 50%;
  transform: translateX(-50%);
}

.tl-dot:after {
  width: 0;
  height: auto;
  top: 25px;
  bottom: -15px;
  border-right-width: 0;
  border-top-width: 0;
  border-bottom-width: 0;
  border-radius: 0;
}

.tl-content p:last-child {
  margin-bottom: 0;
}

.tl-detail {
  font-size: 0.85em;
}

.tl-date {
  font-size: 0.85em;
  margin-top: 2px;
  min-width: 100px;
  max-width: 100px;
}
.b-warning {
  border-color: #ffc700 !important;
}

.b-primary {
  border-color: #009ef7 !important;
}

.b-success {
  border-color: #50cd89 !important;
}

.b-danger {
  border-color: #f1416c !important;
}
</style>
