<script>
import { ref, provide, computed } from "vue";

export default {
  name: "TabPanels",
  props: {
    modelValue: [String, Number],
  },
  setup(props) {
    provide("tabsPanelState", {
      active: computed(() => props.modelValue),
      panels: ref([]),
    });
  },
};
</script>
<style scoped>
.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.rounded-bl-md {
  border-bottom-left-radius: 0.375rem;
}
.rounded-br-md {
  border-bottom-right-radius: 0.375rem;
}
.overflow-hidden {
  overflow: hidden;
}
</style>
<template>
  <div>
    <slot />
  </div>
</template>
