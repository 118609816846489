<template>
  <div class="vld-parent">
    <loading
      :active="isLoading"
      :can-cancel="false"
      :is-full-page="fullPage"
      loader="dots"
      :color="color"
    ></loading>
    <div class="card-body mt-2 white">
      <div class="title text-end">
        <i class="bi bi-journal-text"
          ><span style="font-size: 14px; margin-left: 5px; color: black"
            >ประวัติการแจ้ง</span
          ></i
        >
      </div>
      <div class="p-10">
        <tabs v-model="active">
          <tab>รอตรวจสอบ <span v-if="Appeal_wait.length > 0">({{Appeal_wait.length}})</span></tab>
          <tab>รับคำร้อง <span v-if="Appeal_receive.length > 0">({{Appeal_receive.length}})</span></tab>
          <tab>ดำเนินการ <span v-if="Appeal_processing.length > 0">({{Appeal_processing.length}})</span></tab>
          <tab>เสร็จสิ้น <span v-if="Appeal_finish.length > 0">({{Appeal_finish.length}})</span></tab>
        </tabs>
      </div>
    </div>

    <tab-panels v-model="active">
      <tab-panel>
        <template v-if="Appeal_wait.length > 0">
          <div class="mb-3">
            <div
              class="card-body mt-3 white"
              v-for="(appeal, index) in Appeal_wait"
              :key="index"
            >
              <div class="history">
                <div class="history-header">
                  <div class="history-title">{{ appeal.title }}</div>
                  <div class="history-status">{{ appeal.status }}</div>
                </div>
                <div class="history-body">
                  <div class="history-image">
                    <img :src="appeal.icon" class="history-image-logo" alt="" />
                  </div>
                  <div class="history-detail">{{ appeal.detail }}</div>
                  <div class="history-botton">
                    <button
                      @click="setAppealTo(appeal)"
                      type="button"
                      class="btn see-more"
                    >
                      ดูรายละเอียด
                    </button>
                  </div>
                </div>

                <div class="history-footer">
                  {{ dateThai(appeal.created_at) }}
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="card-body mt-3 white">
            <div class="history-empty">
              <div class="history-empty-text">ยังไม่มีข้อมูล</div>
            </div>
          </div>
        </template>
      </tab-panel>
      <tab-panel>
        <template v-if="Appeal_receive.length > 0">
          <div
            class="card-body mt-3 white"
            v-for="appeal in Appeal_receive"
            :key="appeal.id"
          >
            <div class="history">
              <div class="history-header">
                <div class="history-title">{{ appeal.title }}</div>
                <div class="history-status">{{ appeal.status }}</div>
              </div>
              <div class="history-body">
                <div class="history-image">
                  <img :src="appeal.icon" class="history-image-logo" alt="" />
                </div>
                <div class="history-detail">{{ appeal.detail }}</div>
                <div class="history-botton">
                  <button
                    @click="setAppealTo(appeal)"
                    type="button"
                    class="btn see-more"
                  >
                    ดูรายละเอียด
                  </button>
                </div>
              </div>
              <div class="history-footer">
                {{ dateThai(appeal.created_at) }}
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="card-body mt-3 white">
            <div class="history-empty">
              <div class="history-empty-text">ยังไม่มีข้อมูล</div>
            </div>
          </div>
        </template>
      </tab-panel>
      <tab-panel>
        <template v-if="Appeal_processing.length > 0">
          <div
            class="card-body mt-3 white"
            v-for="appeal in Appeal_processing"
            :key="appeal.id"
          >
            <div class="history">
              <div class="history-header">
                <div class="history-title">{{ appeal.title }}</div>
                <div class="history-status">{{ appeal.status }}</div>
              </div>
              <div class="history-body">
                <div class="history-image">
                  <img :src="appeal.icon" class="history-image-logo" alt="" />
                </div>
                <div class="history-detail">{{ appeal.detail }}</div>
                <div class="history-botton">
                  <button
                    @click="setAppealTo(appeal)"
                    type="button"
                    class="btn see-more"
                  >
                    ดูรายละเอียด
                  </button>
                </div>
              </div>
              <div class="history-footer">
                {{ dateThai(appeal.created_at) }}
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="card-body mt-3 white">
            <div class="history-empty">
              <div class="history-empty-text">ยังไม่มีข้อมูล</div>
            </div>
          </div>
        </template>
      </tab-panel>
      <tab-panel>
        <template v-if="Appeal_finish.length > 0">
          <div
            class="card-body mt-3 white"
            v-for="appeal in Appeal_finish"
            :key="appeal.id"
          >
            <div class="history">
              <div class="history-header">
                <div class="history-title">{{ appeal.title }}</div>
                <div class="history-status">{{ appeal.status }}</div>
              </div>
              <div class="history-body">
                <div class="history-image">
                  <img :src="appeal.icon" class="history-image-logo" alt="" />
                </div>
                <div class="history-detail">{{ appeal.detail }}</div>
                <div class="history-botton">
                  <button
                    @click="setAppealTo(appeal)"
                    type="button"
                    class="btn see-more"
                  >
                    ดูรายละเอียด
                  </button>
                </div>
              </div>
              <div class="history-footer">
                {{ dateThai(appeal.created_at) }}
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="card-body mt-3 white">
            <div class="history-empty">
              <div class="history-empty-text">ยังไม่มีข้อมูล</div>
            </div>
          </div>
        </template>
      </tab-panel>
    </tab-panels>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref, computed, inject } from "vue";
import Tabs from "@/components/Tabs";
import Tab from "@/components/Tab";
import TabPanels from "@/components/TabPanels";
import TabPanel from "@/components/TabPanel";
import moment from "moment";
import useAppeals from "@/service/api/appeal";
import Loading from "vue-loading-overlay";
import { SET_APPEAL_TO } from "@/store/modules/AppealModel";

import "vue-loading-overlay/dist/vue-loading.css";
export default defineComponent({
  name: "Profile",
  components: {
    Tabs,
    Tab,
    TabPanels,
    TabPanel,
    Loading,
  },
  setup() {
    const { getAppeals } = useAppeals();
    const store = inject("store");
    const router = inject("router");
    const active = ref(0);
    const isLoading = ref(true);
    const fullPage = ref(false);
    const appeals = ref([]);
    const color = ref(process.env.VUE_APP_COLOR);

    onMounted(() => {
      getAppeals().then((res) => {
        appeals.value = res.data.data;
        isLoading.value = false;
      });
    });

    function dateThai(date) {
      let y = moment(date).format("yyy");
      let year = parseInt(y) + 543;
      let march = moment(date).locale("th");

      return march.format("dddที่ D MMMM พ.ศ. " + year + " เวลา HH : mm : ss");
    }

    const Appeal_wait = computed(() => {
      return appeals.value.filter((item) => item.status === "รอตรวจสอบ");
    });

    const Appeal_receive = computed(() => {
      return appeals.value.filter((item) => item.status === "รับคำร้อง");
    });

    const Appeal_processing = computed(() => {
      return appeals.value.filter((item) => item.status === "ดำเนินการ");
    });

    const Appeal_finish = computed(() => {
      return appeals.value.filter((item) => item.status === "เสร็จสิ้น");
    });

    const setAppealTo = (val) => {
      let appeal = {
        detail: val,
      };
      store.dispatch(SET_APPEAL_TO, appeal);
      router.push({ name: "Appeal_Detail" });
    };

    return {
      active,
      dateThai,
      Appeal_wait,
      Appeal_receive,
      Appeal_processing,
      Appeal_finish,
      isLoading,
      fullPage,
      color,
      setAppealTo,
    };
  },
});
</script>

<style scoped>
.card-body {
  padding: 0;
}

.title {
  background-color: white;
  width: 100%;
  height: 50px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  padding: 15px 10px;
}
.title-text {
  font-size: 12px;
  margin-right: 5px;
}
.icon-logo {
  height: 27px;
  background-color: white;
  margin-left: 10px;
  border-radius: 50%;
  margin-top: -10px;
}
.body-text {
  height: 16rem;
  position: relative;
}
.hide-scrollbar {
  position: relative;
  height: 100%;
  overflow-y: hidden;
  scrollbar-width: none;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}
.scrollbar {
  height: 0.25rem;
  border-radius: 0.0625rem;
  position: absolute;
  bottom: 0.3125rem;
  left: calc(50% - 0.75rem);
  width: 1.5rem;
  background: var(--color);
}
.scrollbar-st {
  background: #ee4d2d;
  width: 10.801px;
  transform: translateX(0px);
}
.appeal-title {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 0.125rem;
}

.appeal-item {
  width: 25%;
  padding: 0 0.0625rem;
  text-align: center;
  height: 7rem;
}
.appeal-image {
  position: relative;
}
.appeal-image-logo {
  vertical-align: bottom;
  display: inline-block;
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
  border: 1.3px solid #dee2e6;
  border-radius: 15px;
}
.history-image {
  position: relative;
}
.history-image-logo {
  vertical-align: bottom;
  display: inline-block;
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
  border: 1.3px solid #dee2e6;
  border-radius: 15px;
}
.appeal-text {
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  max-height: 2.625rem;
  font-size: 12px;
  line-height: 0.875rem;
  color: rgba(0, 0, 0, 0.87);
  word-wrap: break-word;
  overflow: hidden;
  white-space: normal;
  height: 30px;
}
.history-header {
  padding: 10px;
  display: flex;
}
.history-title {
  width: 50%;
  margin-left: 5px;
  font-size: 13px;
  font-weight: 500;
}
.history-status {
  width: 50%;
  font-size: 12px;
  text-align: end;
}
.history-body {
  padding: 10px;
  display: flex;
  position: relative;
}
.history-detail {
  margin-left: 10px;
}
.history-footer {
  font-size: 12px;
  padding: 10px;
  margin-left: 5px;
}
.history-botton {
  position: absolute;
  right: 10px;
  bottom: 0;
}
.see-more {
  background-color: var(--color) !important;
  border-color: var(--color) !important;
  color: white;
}
.history-empty {
  position: relative;
  height: 157.5px;
}
.history-empty-text {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -10px 0 0 -40px;
  opacity: 0.8;
}
</style>
