<template>
  <div class="vld-parent">
    <loading
      :active="isLoading"
      :can-cancel="false"
      :is-full-page="false"
      loader="dots"
      :color="color"
    ></loading>
    <div class="card-body mt-2 white">
      <div class="title">
        <i class="bi bi-award"
          ><span style="font-size: 14px; margin-left: 5px; color: black"
            >ประเมินความพึงพอใจ และ ข้อเสนอแนะ</span
          ></i
        >
      </div>

      <div class="body-text mt-5" v-if="rating">
        <!--begin::Row-->
        <div class="row mb-7 mt-2 w-100">
          <!--begin::Label-->
          <label class="col-12 text-muted">ประเมินความพึงพอใจ</label>
          <!--end::Label-->
          <!--begin::Col-->
          <div class="col-12">
            <star-rating
              class="mb-2"
              v-bind:star-size="30"
              v-bind:show-rating="false"
              :animate="true"
              v-model:rating="rating"
              :active-border-color="['#F6546A', '#a8c3c0']"
              :border-width="1"
              :rounded-corners="true"
              :active-on-click="true"
              :read-only="true"
            ></star-rating>
          </div>

          <!--end::Col-->
        </div>
        <!--end::Row-->

        <!--begin::Row-->
        <div class="row mb-7 mt-2 w-100">
          <label class="col-12 text-muted">ข้อเสนอแนะ</label>
          <div class="col-12">
            <textarea
              class="form-control"
              id="detail"
              v-model="comment"
              rows="3"
              disabled
            ></textarea>
          </div>
        </div>
      </div>

      <div class="body-text mt-5" v-else>
        <!--begin::Row-->
        <div class="row mb-7 mt-2 w-100">
          <!--begin::Label-->
          <label class="col-12 text-muted">ประเมินความพึงพอใจ</label>
          <!--end::Label-->
          <!--begin::Col-->

          <div class="col-12">
            <star-rating
              class="mb-2"
              v-bind:star-size="30"
              v-bind:show-rating="false"
              :animate="true"
              v-model:rating="rating_n"
              :active-border-color="['#F6546A', '#a8c3c0']"
              :border-width="1"
              :rounded-corners="true"
              :active-on-click="true"
            ></star-rating>
          </div>

          <!--end::Col-->
        </div>
        <!--end::Row-->

        <!--begin::Row-->
        <div class="row mb-7 mt-2 w-100">
          <label class="col-12 text-muted">ข้อเสนอแนะ</label>
          <div class="col-12">
            <textarea
              class="form-control"
              id="detail"
              v-model="comment"
              rows="3"
            ></textarea>
          </div>
          <div class="col-12 mt-2">
            <button
              type="button"
              @click="sendRating"
              style="width: 100px"
              class="button"
            >
              ยืนยัน
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import StarRating from "vue-star-rating";
import { defineComponent, computed, ref, inject } from "vue";
import useSweetalert from "@/service/sweetalert2";
import useAppeal from "@/service/api/appeal";
export default defineComponent({
  name: "Appeal-Detail-Detail",
  props: ["appeals"],
  components: {
    StarRating,
    Loading,
  },
  setup(props) {
    const router = inject("router");
    let rating_n = ref(0);
    let comment = ref(props.appeals.comment);
    let isLoading = ref(false);
    let color = ref(process.env.VUE_APP_COLOR);
    const { SendRating } = useAppeal();
    const { Sconfirm, SconfirmNc } = useSweetalert();
    const rating = computed(() => {
      return props.appeals.score;
    });

    function sendRating() {
      if (rating_n.value) {
        Sconfirm("ยืนยัน ประเมินความพึงพอใจ", "question").then(
          async (result) => {
            if (result.isConfirmed) {
              isLoading.value = true;
              let data = {
                id: props.appeals.id,
                rating: rating_n.value,
                comment: comment.value,
              };
              await SendRating(data)
                .then(() => {
                  isLoading.value = false;
                  SconfirmNc("คะแนนความพึงพอใจ สำเร็จ", "success", "ตกลง").then(
                    () => {
                      router.push({ name: "Home" });
                    }
                  );
                })
                .catch(() => {
                  isLoading.value = false;
                  SconfirmNc(
                    "เกิดข้อผิดพลาด กรุณาทำรายการใหม่อีกครั้ง",
                    "error",
                    "ตกลง"
                  );
                });
            }
          }
        );
      } else {
        SconfirmNc("กรุณาให้คะแนนความพึงพอใจ", "error", "ตกลง");
      }
    }

    return {
      rating,
      rating_n,
      comment,
      sendRating,
      isLoading,
      color,
    };
  },
});
</script>

<style scoped></style>
